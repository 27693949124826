
















import { defineComponent, reactive, toRefs } from "@vue/composition-api"
export default defineComponent({
    setup() {
        const state = reactive({})
        return {
            ...toRefs(state),
        }
    },
})
